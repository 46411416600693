import { Fragment, ReactNode, useEffect, useState } from "react";
import {
  aTagClick,
  dataImage,
  fatchData,
  scrollTop,
  scroll_,
  stickyNav,
  // wowJsAnimation,
} from "../utilits";
import Header from "./Header";
import { SiteSetting } from "../types/site.interface";
import ProgressBar from "./ProgressBar";
import MobileMenu from "./MobileMenu";
import PreLoader from "./PreLoader";
import { useSiteSettingsStore } from "../store/firebase.store";

const Layout = ({ children, dark }: { children: ReactNode; dark: boolean }) => {
  const {
    data: siteInfo,
    loading,
    error,
    fetchSiteInfo,
  } = useSiteSettingsStore();

  useEffect(() => {
    fetchSiteInfo();
  }, [fetchSiteInfo]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const siteInfoData = await getSiteInfo();
  //     setSiteInfo(siteInfoData);
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    // wowJsAnimation();
    aTagClick();
    window.addEventListener("scroll", scroll_);
    window.addEventListener("scroll", stickyNav);
    window.addEventListener("scroll", scrollTop);
  }, []);

  return (
    <>
      <PreLoader />
      {/* <ImageView /> */}
      {/* <VideoPopup /> */}
      <div
        className="jpdesign_tm_all_wrap"
        data-magic-cursor="show"
        id="tsparticles"
      >
        <MobileMenu />
        <Header
          logo={
            (siteInfo &&
              siteInfo.logo &&
              siteInfo.logo[dark ? "dark" : "light"]) ||
            ""
          }
        />
        {children}
        {/* <CopyRight brandName={siteInfo && siteInfo.brandName} /> */}
        {/* <Cursor /> */}
        <ProgressBar />
      </div>
    </>
  );
};
export default Layout;
