import React from "react";
import { IWorkExperience } from "../types/firebase.store.interface";
import { format } from "date-fns";

interface ModalContentProps {
  activeTab: "role" | "responsibilities" | "about";
  experience: IWorkExperience;
}

const ModalContent: React.FC<ModalContentProps> = ({
  activeTab,
  experience,
}) => {
  const currentTime = format(new Date(), "hh:mm:ss a");
  return (
    <div className="console-log">
      <div className="log-header">
        <span className="branch">main</span> | {activeTab}
      </div>
      <br />
      <div className="log-contents">
        {activeTab === "role" && (
          <p>
            {experience.summary}
            <span className="cursor">|</span>
          </p>
        )}
        {activeTab === "responsibilities" && (
          <ul>
            {experience.details &&
              experience.details.map((detail, index) => (
                <li key={index}>
                  • {detail}
                  <br />
                </li>
              ))}
            <span className="cursor">|</span>
          </ul>
        )}
        {activeTab === "about" && (
          <p>
            {experience.companyAbout}
            <span className="cursor">|</span>
          </p>
        )}
      </div>

      <div className="log-status">
        <span className="version">Node 20.18.0</span>
        <span className="time">{currentTime}</span>
      </div>
    </div>
  );
};

export default ModalContent;
