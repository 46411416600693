/* eslint-disable jsx-a11y/img-redundant-alt */
import { Key, useEffect, useRef, useState } from "react";
import { activeSkillProgress, throttledResize } from "../utilits";
import parse from "html-react-parser";
import Counter from "./Counter";
import SkillTooltip from "./SkillTooltip";
import styled from "styled-components";
import Notification from "./Notifications";
import NotificationManager from "./NotificationManager";
import { Skill, SkillNames } from "../types/info.interface";
import { useSkillsProgressStore } from "../store/firebase.store";

const getYearsBasedOnValue = (value: number) => {
  return Math.floor(value / 10);
};

const SkillProgress = () => {
  const {
    data: skills,
    loading,
    error,
    fetchSkills,
  } = useSkillsProgressStore();
  const notificationManagerRef = useRef<any>(null);
  const [displayedSkills, setDisplayedSkills] = useState<Skill[]>([]);
  const dodoProgressRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    fetchSkills();
  }, [fetchSkills]);

  useEffect(() => {
    if (Array.isArray(skills) && skills.length > 0) {
      const sortedSkills = [...skills].sort((a, b) => b.value - a.value);
      setDisplayedSkills(sortedSkills);
    }
  }, [skills]);

  useEffect(() => {
    const handleScroll = () => {
      activeSkillProgress();
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      activeSkillProgress();
    };

    const dodoProgressElement = dodoProgressRef.current;
    if (dodoProgressElement) {
      dodoProgressElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dodoProgressElement) {
        dodoProgressElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  if (loading) {
    return <div>loading...</div>;
  }

  if (error) {
    return <div>error...</div>;
  }

  const handleAddNotification = (skill: Skill) => {
    console.log("handleAddNotification", skill);
    if (notificationManagerRef.current) {
      notificationManagerRef.current.addNotification(skill);
    }
  };

  return (
    <div className="jpdesign_tm_section">
      <div className="jpdesign_tm_skills">
        <div className="container">
          <div className="wrapper">
            <div
              className="jpdesign_tm_main_title wow fadeInUp"
              data-wow-duration="1s"
              data-align="left"
            >
              <div className="title-container">
                <div className="text-content">
                  <span>Software Skills</span>
                  <h3>Skills over the Years</h3>
                  <p>
                    Constantly learning and practicing with the latest
                    technologies in the industry. While trying to apply
                    'Depth-first Search' to my core skills.
                  </p>
                </div>
                <div className="img-content">
                  <img
                    src={`img/logo/brand-image.png`}
                    alt="image"
                    className="brand-image"
                  />
                </div>
              </div>
            </div>

            <div
              className="dodo_progress wow fadeInUp"
              data-wow-duration="1s"
              ref={dodoProgressRef}
            >
              {Array.isArray(displayedSkills) &&
                displayedSkills.map((skill: Skill, i: Key) => (
                  <div
                    className="progress_inner skillsInner___"
                    data-value={skill.value}
                    data-color={skill.color}
                    key={i}
                    onClick={() => handleAddNotification(skill)}
                  >
                    <span>
                      <NotificationManager
                        ref={notificationManagerRef}
                        skill={skill}
                      />
                      <span className="icon">{parse(skill.icon)}</span>
                      <span className="label">
                        {
                          SkillNames[
                            skill.name.replace(
                              /\s+/g,
                              ""
                            ) as keyof typeof SkillNames
                          ]
                        }
                      </span>
                      <span className="number">
                        <b>
                          <Counter end={getYearsBasedOnValue(skill.value)}>
                            Years
                          </Counter>
                        </b>
                      </span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div className="bar_in">
                          {/* <div
                            className="text"
                            onMouseEnter={fitText}
                            style={{ background: skill.color }}
                          >
                            <p>{skill.description}</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillProgress;
