export interface Info {
  name?: string
  address?: string
  bio?: string
  mainSkill?: string
  img?: string
  skills?: Skill[]
  social?: Social[]
  contact?: Contact
}

export interface Skill {
  name: string;
  icon: string;
  color: string;
  value: number;
  description: string;
  hueCode?: string;
}

export enum SkillNames {
  amplitude = "Amplitude",
  angular = "Angular",
  docker = "Docker",
  ffmpeg = "FFmpeg",
  firebase = "Firebase",
  gcp = "Google Cloud Platform",
  grafana = "Grafana",
  graphql = "GraphQL",
  honeycomb = "Honeycomb",
  java = "Java",
  javascript = "JavaScript",
  mediaapi = "MediaStream API",
  nextjs = "Next.js",
  nodejs = "Node.js",
  postgres = "Postgres",
  python = "Python",
  react = "React",
  redux = "Redux",
  rust = "Rust",
  rxjs = "RxJS",
  sentry = "Sentry",
  temporalio = "Temporal.io",
  typescript = "TypeScript",
  vercel = "Vercel",
  vim = "Vim",
  vscode = "VSCode",
  webrtc = "WebRTC",
}

export interface Social {
  name: string
  icon: string
  url: string
}

export interface Contact {
  address: string
  email: string
  phn: string
}

export interface WorkExperience {
  id: number;
  company: string;
  position: string;
  icon: string;
  skillsUsed: string[];
  companyAbout: string;
  summary: string;
  startDate: string;
  endDate: string;
  companyUrl: string;
  details?: string[];
}
