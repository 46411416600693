/* eslint-disable jsx-a11y/img-redundant-alt */
import { useQuery } from "react-query";
import { Highlights } from "../types/highlights.interface";
import parse from "html-react-parser";

const fetchHighlights = async () => {
  const res = await fetch("/static/workProcess.json");
  const data = await res.json();
  return data;
};

const Process = () => {
  const { data, status } = useQuery("highlights", fetchHighlights) as {
    data: Highlights[];
    status: string;
  };


  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "error") {
    return <div>Error</div>;
  }

  return (
    <div className="jpdesign_tm_section" id="process">
      <div className="jpdesign_tm_process">
        <div className="container">
          <div className="list">
            <ul>
              {data &&
                data.map((data, i) => (
                  <li className="wow fadeInUp" data-wow-duration="1s" key={i}>
                    <div className="list_inner">
                      <div className="icon">
                        <span>{parse(data.icons.dark)}</span>
                      </div>
                      <div className="title">
                        <h3>{data.title}</h3>
                      </div>
                      <div className="text">
                        <p>{data.dec}</p>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Process;
