/* eslint-disable jsx-a11y/img-redundant-alt */
import parse from "html-react-parser";
import { useEffect, useState, useMemo, Key } from "react";
import { useQuery } from "react-query";
import { Info, Social } from "../types/info.interface";
import ReactTypingEffect from "react-typing-effect";
import { DockHeader } from "../layout/DockHeader/DockHeader";
import SkillBadges from "./SkillBadges";

const fetchInfo = async () => {
  const res = await fetch("/static/info.json");
  const data = await res.json();
  return data;
};

const Home = ({ dark = true }) => {
  const { data, status } = useQuery("info", fetchInfo) as {
    data: Info;
    status: string;
  };

  

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "error") {
    return <div>Error</div>;
  }



  return (
    <>
      <div className="jpdesign_tm_section" id="home">
        <div className="jpdesign_tm_hero">
          <div className="container">
            <div className="content">
              <div className="details">
                <div className="hello">
                  <h3 className="orangeText">{`Welcome, My Name is`}</h3>
                </div>
                <div className="name">
                  <h3>{data && data.name ? data.name : "name"}</h3>
                </div>
                <div className="job">
                  <span className="orangeText">
                    <ReactTypingEffect
                      cursor="_"
                      text={data.mainSkill as string}
                      speed={40}
                      eraseSpeed={10}
                      eraseDelay={1500}
                      typingDelay={100}
                    />
                  </span>{" "}
                </div>
                <div className="text">
                  <p>{data.bio}</p>
                </div>
                <div className="button">
                  <div className="jpdesign_tm_button">
                    <a href="#about">
                      <span>About Me</span>
                    </a>
                  </div>
                  <div className="social">
                    <ul>
                      {data &&
                        data.social &&
                        data.social.map(
                          (social: Social, i: Key | null | undefined) => (
                            <li key={i}>
                              <a
                                href={social.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className={social.icon} />
                              </a>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="avatar">
                <div className="image">
                  <img
                    src={
                      data && data.img
                        ? data.img
                        : "/img/slider/faceshotOne.jpg"
                    }
                    alt="image"
                  />
                  {data && data.skills && <SkillBadges skills={data.skills} />}
                </div>
              </div>
            </div>
          </div>
          <div className="jpdesign_tm_down">
            <a className="anchor" href="#process">
              <svg
                id="Layer_1"
                height="512"
                viewBox="0 0 91 91"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                style={{
                  fill: "#FFF",
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: "1.5",
                }}
              >
                <path d="m45.31 90a1.5 1.5 0 0 1 -1.5-1.5v-12.56a1.5 1.5 0 0 1 3 0v12.6a1.5 1.5 0 0 1 -1.5 1.46z" />
                <path d="m45.31 90a1.51 1.51 0 0 1 -1-.37l-8-7a1.5 1.5 0 0 1 2-2.26l8 7a1.51 1.51 0 0 1 .14 2.12 1.49 1.49 0 0 1 -1.14.51z" />
                <path d="m45.31 90a1.49 1.49 0 0 1 -1.13-.51 1.51 1.51 0 0 1 .14-2.12l8-7a1.5 1.5 0 0 1 2 2.26l-8 7a1.51 1.51 0 0 1 -1.01.37z" />
                <path d="m45.31 17a1.5 1.5 0 0 1 -1.5-1.5v-12.61a1.5 1.5 0 0 1 3 0v12.6a1.5 1.5 0 0 1 -1.5 1.51z" />
                <path d="m37.28 11.41a1.5 1.5 0 0 1 -1-2.63l8-7a1.5 1.5 0 1 1 2.02 2.22l-8 7a1.56 1.56 0 0 1 -1.02.41z" />
                <path d="m53.33 11.41a1.51 1.51 0 0 1 -1-.37l-8-7a1.5 1.5 0 0 1 2-2.26l8 7a1.5 1.5 0 0 1 -1 2.63z" />
                <path d="m50.24 69.55h-9.86a13.64 13.64 0 0 1 -13.63-13.62v-20.43a13.64 13.64 0 0 1 13.63-13.62h9.86a13.64 13.64 0 0 1 13.62 13.62v20.43a13.64 13.64 0 0 1 -13.62 13.62zm-9.86-44.67a10.64 10.64 0 0 0 -10.63 10.62v20.43a10.64 10.64 0 0 0 10.63 10.62h9.86a10.63 10.63 0 0 0 10.62-10.62v-20.43a10.63 10.63 0 0 0 -10.62-10.62z" />
                <path d="m45.31 42.58a1.5 1.5 0 0 1 -1.5-1.5v-6.71a1.5 1.5 0 0 1 3 0v6.71a1.5 1.5 0 0 1 -1.5 1.5z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
