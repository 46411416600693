import React from "react";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { Skill } from "../types/info.interface";

const NotificationWrapper = styled(animated.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 10px; /* Add margin to separate multiple notifications */
`;

interface NotificationProps {
  message: string;
  skill: Skill;
}

const Notification: React.FC<NotificationProps> = ({ message }) => {
  const styles = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 220, friction: 20 },
  });

  return <NotificationWrapper style={styles}>{message}</NotificationWrapper>;
};

export default Notification;
