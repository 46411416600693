import React, { useState, useImperativeHandle, forwardRef } from "react";
import Notification from "./Notifications";
import { Skill } from "../types/info.interface";

interface NotificationItem {
  id: number;
  message: string;
  skill: Skill;
}

interface NotificationManagerProps {
  skill: Skill;
}

const NotificationManager = forwardRef(
  ({ skill }: NotificationManagerProps, ref) => {
    const [notifications, setNotifications] = useState<NotificationItem[]>([]);

    // Method to add notification
    const addNotification = (message: string) => {
      const newNotification: NotificationItem = {
        id: Date.now(),
        message,
        skill,
      };
      setNotifications((prev) => [...prev, newNotification]);
    };

    // Expose the addNotification function to the parent component via ref
    useImperativeHandle(ref, () => ({
      addNotification,
    }));

    return (
      <div>
        <div>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              message={skill.description}
              skill={skill}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default NotificationManager;
