import Counter from "./Counter";

const About = ({ dark = true }) => {
  return (
    <div className="jpdesign_tm_section" id="about">
      <div className="jpdesign_tm_about">
        <div className="container">
          <div className="wrapper">
            <div className="left">
              <div className="image">
                <img src={`img/about/jeancon.png`} alt="image" />
                <div className="numbers year">
                  <div className="wrapper">
                    <h3>
                      <Counter end={11} />
                    </h3>
                    <span className="name">
                      Years of
                      <br />
                      Experience
                    </span>
                  </div>
                </div>
                <div className="numbers project">
                  <div className="wrapper">
                    <h3>
                      <Counter end={40} />
                    </h3>
                    <span className="name">
                      Full-Stack
                      <br />
                      Projects
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="title wow fadeInUp" data-wow-duration="1s">
                <span>{`Staff Full-Stack Software Engineer`}</span>
                <h3>
                  Turning complex problems into elegant solutions.
                  {/* Simplifying the complex. <br /> Amplifying the
                  <strong>impact</strong>. */}
                </h3>
              </div>
              <div className="text wow fadeInUp" data-wow-duration="1s">
                <p>
                  {`My name is Jeancarlos Perez. A humble Software Engineer with a Master of Science in Computer Engineering and over a decade of experience in the software development industry, working with both large enterprises and innovative startups. I bring expertise in mentoring junior engineers, leading technical design, and developing scalable solutions using modern web frameworks and software architecture design patterns. My extensive background spans a range of technologies and methodologies, consistently driving successful outcomes in diverse engineering environments.`}
                </p>
              </div>
              <div
                className="jpdesign_tm_button wow fadeInUp"
                data-wow-duration="1s"
              >
                <a className="anchor" href="#contact">
                  <span>Connect with Me</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="brush_1 wow fadeInLeft" data-wow-duration="1s">
          <img src="img/brushes/about/1.png" alt="image" />
        </div>
        <div className="brush_2 wow fadeInRight" data-wow-duration="1s">
          <img src="img/brushes/about/2.png" alt="image" />
        </div> */}
      </div>
    </div>
  );
};
export default About;
