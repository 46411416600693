import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import TimelineIcon from "./TimelineIcon";
import { useWorkExpStore } from "../store/firebase.store"; // Adjust the import path as needed
import { IWorkExperience } from "../types/firebase.store.interface";
import { SetStateAction, useEffect, useState } from "react";
import { WorkExperience } from "src/types/info.interface";
import ExperienceModal from "./ExperienceModal"; // Import the ExperienceModal component
import SkillsChain from "./SkillsChain";
import LinkedSkillInfo from "./SkillsChain";

const Timeline = () => {
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);

  const [selectedExperience, setSelectedExperience] =
    useState<IWorkExperience | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (experience: IWorkExperience) => {
    setSelectedExperience(experience);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedExperience(null);
    setIsModalOpen(false);
  };
  const handleMouseEnter = (id: number) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setHoveredElement(id);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHoveredElement(null);
    }, 1000); // Adjust the delay as needed (500ms in this example)
    setHoverTimeout(timeout);
  };
  const {
    data: workExperiences,
    loading,
    error,
    fetchWorkExperience,
  } = useWorkExpStore();

  useEffect(() => {
    fetchWorkExperience();
  }, [fetchWorkExperience]);

  if (loading) {
    return <div>loading...</div>;
  }

  if (error) {
    return <div>error...</div>;
  }
  return (
    <>
      <VerticalTimeline layout="2-columns" animate={true}>
        {workExperiences
          .sort((a: IWorkExperience, b: IWorkExperience) => a.id - b.id)
          .map((experience: IWorkExperience) => (
            <div
              key={experience.id}
              onMouseEnter={() => handleMouseEnter(experience.id)}
              onMouseLeave={handleMouseLeave}
              onClick={() => openModal(experience)}
              style={{ position: "relative", marginBottom: "250px" }} // Ensure the relative position of the container
            >
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgba(247, 80, 35, 0)",
                  color: "#fff",
                  opacity: hoveredElement === experience.id ? 1 : 0.7,
                  padding: "10px",
                  borderRadius: "8px",
                }}
                position={experience.id % 2 === 0 ? "right" : "left"}
                contentArrowStyle={{
                  borderRight: "7px solid rgba(247, 80, 35, 0.7)",
                }}
                date={experience.startDate}
                dateClassName="date"
                iconStyle={{
                  background: "rgba(247, 80, 35, 0.7)",
                  color: "#000",
                }}
                icon={<TimelineIcon src={experience.icon} />}
              >
                <div
                  className="vertical-timeline-element-title"
                  style={{
                    textAlign:
                      window.innerWidth > 1080
                        ? experience.id % 2 === 0
                          ? "left"
                          : "right"
                        : "left",
                  }}
                >
                  <h4>{experience.position}</h4>
                  <h3>
                    <strong>{experience.company}</strong>
                  </h3>
                </div>
                {hoveredElement === experience.id && (
                  <div
                    style={{
                      position: "absolute",
                      marginTop: "10px",
                      alignContent: "right",
                      display: "flex",
                      justifyContent:
                        experience.id % 2 === 0 ? "left" : "right",
                      animation:
                        hoveredElement === experience.id
                          ? experience.id % 2 === 0
                            ? "bounceInRight 0.6s ease"
                            : "bounceInLeft 0.6s ease"
                          : "fadeOut 1s ease",
                    }}
                  >
                    <LinkedSkillInfo
                      usedSkills={experience.skillsUsed}
                      appearance="timeline"
                    />
                  </div>
                )}
              </VerticalTimelineElement>
            </div>
          ))}
      </VerticalTimeline>
      {isModalOpen && (
        <ExperienceModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          experience={selectedExperience}
        />
      )}
    </>
    // <VerticalTimeline>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--work"
    //     contentStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     contentArrowStyle={{ borderRight: "7px solid  rgba(247, 80, 35, 0.7)" }}
    //     date="2023 - present"
    //     iconStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/descript.jpeg" />}
    //   >
    //     <h4 className="vertical-timeline-element-title">
    //       Staff Software Engineer <strong>@Descript</strong>
    //     </h4>
    //     <h2 className="vertical-timeline-element-subtitle">Miami, FL</h2>
    //     <p>
    //       As a Staff Software Engineer, I employ advanced programming skills in
    //       TypeScript, Angular, RxJs, React, Redux, Redux Sagas, NodeJS,
    //       Temporal.io, and GCP to enhance our software solutions. I
    //       independently manage the integration and optimization of software from
    //       an acquired company, ensuring product stability for over 15,000
    //       customers.
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--work"
    //     contentStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     contentArrowStyle={{ borderRight: "7px solid  rgba(247, 80, 35, 0.7)" }}
    //     date="2020 - 2023"
    //     iconStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/squadcast.jpeg" />}
    //   >
    //     <h4 className="vertical-timeline-element-title">
    //       Staff Software Engineer <strong>@SquadCast</strong>
    //     </h4>
    //     <h2 className="vertical-timeline-element-subtitle">Miami, FL</h2>
    //     <p>
    //       As a Staff Software Engineer, I developed full-stack software releases
    //       using TypeScript, Angular, NodeJS, NestJS, and GCP, and led the
    //       migration of a SaaS product into a new parent company, successfully
    //       transferring over 10,000 customer accounts.
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--work"
    //     contentStyle={{
    //       background: "rgba(247, 80, 35, 0.7)",
    //       color: "#fff",
    //     }}
    //     contentArrowStyle={{ borderRight: "7px solid  rgba(247, 80, 35, 0.7)" }}
    //     date="2019 - 2020"
    //     iconStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/banditgym.png" />}
    //   >
    //     <h4 className="vertical-timeline-element-title">
    //       Senior Software Engineer <strong>@BanditGym</strong>
    //     </h4>
    //     <h2 className="vertical-timeline-element-subtitle">Miami, FL</h2>
    //     <p>
    //       Contracted to engineered a single-page application (SPA) using
    //       MongoDB, ExpressJS, React, and NodeJS to unify mobile and web
    //       platforms. Utilizing GraphQL, I designed and implemented efficient
    //       APIs that enhanced data retrieval capabilities and optimized
    //       client-server interactions for improved application performance. I
    //       spearheaded user experience enhancements for the startup's fitness
    //       app, transitioning from Cordova to React Native.
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--education anim_moveTop"
    //     contentStyle={{ background: "rgba(128, 103, 240, 0.7)", color: "#fff" }}
    //     contentArrowStyle={{
    //       borderRight: "7px solid  rgba(128, 103, 240, 0.7)",
    //     }}
    //     date="Dec 2019"
    //     iconStyle={{ background: "rgba(128, 103, 240, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/ucf.png" />}
    //   >
    //     <h3 className="vertical-timeline-element-title">Masters of Science</h3>
    //     <h4 className="vertical-timeline-element-subtitle">
    //       in Computer Engineering
    //     </h4>
    //     <p>
    //       My Areas of Focus: Computer Systems and Very Large Scale Integration
    //       (CS/VLSI), Intelligent Systems and Machine Learning (ISML), Neural
    //       Networks and Deep Learning{" "}
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--work"
    //     contentStyle={{ background: "rgba(247, 80, 35, 0.70)", color: "#fff" }}
    //     contentArrowStyle={{ borderRight: "7px solid  rgba(247, 80, 35, 0.7)" }}
    //     date="2018 - 2020"
    //     iconStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/ng.png" />}
    //   >
    //     <h4 className="vertical-timeline-element-title">
    //       Senior Software Engineer <strong>@Northrop Grumman</strong>
    //     </h4>
    //     <h2 className="vertical-timeline-element-subtitle">Orlando, FL</h2>
    //     <p>
    //       Worked across many different teams implementing software solutions
    //       using Java, Python, C++, Groovy, JavaScript. Created Unit Tests using
    //       Jest, Jenkins, and more.
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--work"
    //     contentStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     contentArrowStyle={{ borderRight: "7px solid  rgba(247, 80, 35, 0.7)" }}
    //     date="2016 - 2018"
    //     iconStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/harris.png" />}
    //   >
    //     <h4 className="vertical-timeline-element-title">
    //       Systems Integration & Test Engineer <strong>@L3 Harris</strong>
    //     </h4>
    //     <h2 className="vertical-timeline-element-subtitle">Orlando, FL</h2>
    //     <p>
    //       Developed software using Java, Groovy, and C++ to use towards testing
    //       scripts and automating tasks. Managed and the SI&T team’s Git
    //       repositories using Bitbucket and reviewed merges, commits, and
    //       conflicts through a peer review process.
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--education anim_moveTop"
    //     contentStyle={{ background: "rgba(128, 103, 240, 0.7)", color: "#fff" }}
    //     contentArrowStyle={{
    //       borderRight: "7px solid  rgba(128, 103, 240, 0.7)",
    //     }}
    //     date="May 2016"
    //     iconStyle={{ background: "rgba(128, 103, 240, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/fiu.png" />}
    //   >
    //     <h3 className="vertical-timeline-element-title">
    //       Bachelors of Science
    //     </h3>
    //     <h4 className="vertical-timeline-element-subtitle">
    //       in Computer Engineering
    //     </h4>
    //     <p>
    //       My Areas of Focus include: Computer Architecture, Microprocessor
    //       Design, Embedded Systems, and Hardware Programming.
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     className="vertical-timeline-element--work"
    //     contentStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     contentArrowStyle={{ borderRight: "7px solid  rgba(247, 80, 35, 0.7)" }}
    //     date="2015 - 2016"
    //     iconStyle={{ background: "rgba(247, 80, 35, 0.7)", color: "#fff" }}
    //     icon={<TimelineIcon src="/img/companies/skypatrol.png" />}
    //   >
    //     <h4 className="vertical-timeline-element-title">
    //       Hardware Engineer <strong>@SkyPatrol</strong>
    //     </h4>
    //     <h2 className="vertical-timeline-element-subtitle">Miami, FL</h2>
    //     <p>
    //       Engineered and executed Python scripts for advanced GPS systems,
    //       focusing on data acquisition and route optimization for various
    //       applications including fleet management, vehicle dealership tracking,
    //       and security for personal assets, ensuring high precision and
    //       reliability in real-time geolocation services.
    //     </p>
    //   </VerticalTimelineElement>
    //   <VerticalTimelineElement
    //     iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
    //   />
    // </VerticalTimeline>
  );
};

export default Timeline;
